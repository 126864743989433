<template>
  <div class="wrapper">
    <div class="userinfo">
      <el-image
        style="width: 70px; height: 70px; border-radius: 5px; margin-right: 10px"
        :src="detail.user.face"
        :preview-src-list="[detail.user.face]"
        fit="contain"
      >
        <div
          style="line-height: 70px; text-align: center; background: #f5f7fa"
          slot="error"
        >无</div>
      </el-image>
      <div class="info">
        <div class="nickname">{{ detail.user.nickname }}</div>
        <div class="nickname">{{ detail.user.mobile }}</div>
        <!-- <div class="nickname">
          <el-tag style="margin-left: 20px" v-if="detail.user.sex == 3" type="primary" size="mini">未知</el-tag>
        </div> -->
      </div>
    </div>
    <div class="content">
      <el-descriptions
        direction="vertical"
        :column="4"
        border
      >
        <el-descriptions-item label="订单号">{{ detail.order_no }}</el-descriptions-item>
        <el-descriptions-item
          label="支付状态"
          :span="2"
        >{{ detail.pay_status_dsc }}</el-descriptions-item>
        <el-descriptions-item label="支付方式">{{ detail.pay_type_dsc }} </el-descriptions-item>
        <el-descriptions-item label="订单金额">{{ detail.order_price }}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{ detail.pay_date }}</el-descriptions-item>
        <el-descriptions-item label="订单创建日期">{{ detail.created_at }}</el-descriptions-item>
        <el-descriptions-item label="三方流水号">{{ detail.transaction_id }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions
        direction="vertical"
        :column="3"
        border
        style="margin: 20px auto;"
      >
        <el-descriptions-item label="商品id">{{ detail.product_id }}</el-descriptions-item>
        <el-descriptions-item label="商品名称">{{ detail.product_name }}</el-descriptions-item>
        <el-descriptions-item label="商品原价">{{ detail.ori_price }}</el-descriptions-item>
        <el-descriptions-item label="商品价格">￥{{ detail.price }}元/{{ detail.year}}</el-descriptions-item>
        <el-descriptions-item label="商品折扣率">{{ detail.discount }}</el-descriptions-item>
        <el-descriptions-item label="年份">{{ detail.year }}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {}
  },

  mounted () { },

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  .userinfo {
    display: flex;
    padding: 0 20px;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
    }
  }
  .content {
    padding: 20px;
  }
}
</style>
